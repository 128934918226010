import * as styles from "../../pages/index.module.css";

import {
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { ValidationError, useForm } from "@formspree/react";

import { PhoneIcon } from "@chakra-ui/icons";

export default function ContactForm() {
  var toast = useToast();

  const [state, handleSubmit] = useForm("xjvqrgzv");

  if (state.succeeded) {
    toast({
      status: "success",
      title: "Message was sent!",
    });
  }

  //initial values + setting new ones by typing in input
  const [formValues, setFormValues] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const handleChange = (input: any) => (e: any) => {
    const value = e.currentTarget.value;

    setFormValues((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  return (
    <div className={styles.contactForm}>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.contactFormInputs}>
          <div className={styles.formTitle}>
            <div className={styles.display4}>Contact Us</div>
          </div>
          <div className={styles.inputFieldWIthLabel}>
            <div className={styles.inputLabel}>
              <div className={styles.fullName}>Full Name</div>
            </div>
            <Input
              className={styles.input}
              variant="outline"
              size="lg"
              id="fullName"
              type="text"
              name="fullName"
              required
              defaultValue={formValues.fullName}
              onChange={handleChange("fullName")}
            />
            <ValidationError
              prefix="fullName"
              field="fullName"
              errors={state.errors}
            />
          </div>
          <div className={styles.inputFieldWIthLabel}>
            <div className={styles.inputLabel}>
              <div className={styles.fullName}>Phone Number</div>
            </div>
            <InputGroup className={styles.input}>
              <InputLeftElement
                pointerEvents="none"
                children={<PhoneIcon color="gray.300" />}
              />
              <Input
                variant="outline"
                size="lg"
                id="phoneNumber"
                type="text"
                name="phoneNumber"
                required
                defaultValue={formValues.phoneNumber}
                onChange={handleChange("phoneNumber")}
              />
            </InputGroup>
          </div>
          <div className={styles.inputFieldWIthLabel}>
            <div className={styles.inputLabel}>
              <div className={styles.fullName}>Email</div>
            </div>
            <Input
              className={styles.input}
              variant="outline"
              size="lg"
              id="email"
              type="email"
              name="email"
              required
              defaultValue={formValues.email}
              onChange={handleChange("email")}
            />
          </div>
          <div className={styles.inputFieldWIthLabel}>
            <div className={styles.inputLabel}>
              <div className={styles.fullName}>Message</div>
            </div>
            <Textarea
              className={styles.input3}
              variant="outline"
              size="lg"
              id="Message"
              name="Message"
              required
              defaultValue={formValues.message}
              onChange={handleChange("message")}
            />
          </div>
          <div
            className="g-recaptcha"
            data-sitekey="6Le9FyEoAAAAAIbkRRG947xGYo0abC62x7yVGvhr"
          ></div>
          <Button
            className={styles.button4}
            variant="solid"
            colorScheme="cdicyan"
            type="submit"
          >
            Send Message
          </Button>
        </div>
      </form>
    </div>
  );
}
