import * as React from "react";
import * as styles from "./index.module.css";

import { FunctionComponent, useCallback, useState } from "react";

import { Button } from "@chakra-ui/react";
import ContactForm from "../components/Contact/ContactForm";
import Layout from "../components/Base/Layout";
import { SEO } from "../components/Base/Seo";
import { navigate } from "gatsby";

const Home: FunctionComponent = () => {
  const onButton4Click = useCallback(() => {
    navigate("/about-us");
  }, []);

  const onButton6Click = useCallback(() => {
    navigate("/about-us");
  }, []);

  return (
    <Layout>
      <div className={styles.home}>
        <div className={styles.heroSection}>
          <div className={styles.heroContent}>
            <div className={styles.logo2}>
              <img
                className={styles.cdihealthlogoIcon2}
                alt=""
                src="../logo-hero.svg"
              />
            </div>
            <div className={styles.heroText}>
              <div className={styles.title}>
                <div className={styles.display}>
                  Affordable solutions for all of your medical product needs
                </div>
              </div>
              <div className={styles.p}>
                <div className={styles.caption}>
                  At Simply Express, we provide a large selection of health and
                  wellness products—including Personal Protective Equipment
                  (PPE), canes, walkers, cushions, and more— we can help you
                  provide your patients with the equipment they need to live
                  healthier, more comfortable lives.
                </div>
              </div>
            </div>
            <div className={styles.heroActions}>
              <Button
                className={styles.button}
                variant="outline"
                colorScheme="white"
                size="lg"
                onClick={onButton4Click}
              >
                About Us
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.cardsSection}>
          <div className={styles.sectionTitle}>
            <h2 className={styles.display1}>What We Offer</h2>
          </div>
          <div className={styles.cardsRow}>
            <div className={styles.cardSlot}>
              <div className={styles.card}>
                <div className={styles.cardContent}>
                  <div className={styles.title}>
                    <b className={styles.label}>Wholesale Medical Equipement</b>
                  </div>
                  <div className={styles.p1}>
                    <div className={styles.caption1}>
                      Simply Express offers medical clinics, medical spas,
                      private practices, and medical supply wholesalers the most
                      competitive pricing for the equipment they need.
                    </div>
                  </div>
                  <Button
                    className={styles.button}
                    variant="solid"
                    colorScheme="cdicyan"
                    onClick={onButton6Click}
                  >
                    Learn More
                  </Button>
                </div>
                <div className={styles.cardImg}>
                  <img
                    className={styles.depositphotos349498906XL1Icon}
                    alt=""
                    src="../why-us-guy.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.whySection}>
          <div className={styles.whyImg}>
            {/* <img
              className={styles.depositphotos349498186S1Icon}
              alt=""
              src="../se-plavusa-section-cutoff.png"
            /> */}
          </div>
          <div className={styles.features}>
            <div className={styles.sectionTitle2}>
              <h2 className={styles.display3}>Why Us</h2>
            </div>
            <div className={styles.featuresRow}>
              <div className={styles.featureSlot}>
                <div className={styles.feature}>
                  <div className={styles.iconBox}>
                    <img
                      className={styles.medicineSyrupIcon}
                      alt=""
                      src="../vaccine-bottle.svg"
                    />
                  </div>
                  <div className={styles.featureLabel}>
                    <b className={styles.label7}>Large Product Range</b>
                  </div>
                  <div className={styles.featuretext}>
                    <div className={styles.label8}>
                      We use an extensive network of suppliers to ensure we have
                      a large selection of medical equipment and products for
                      our clients to choose from.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.featureSlot}>
                <div className={styles.feature}>
                  <div className={styles.iconBox}>
                    <img
                      className={styles.medicineSyrupIcon}
                      alt=""
                      src="../coin.svg"
                    />
                  </div>
                  <div className={styles.featureLabel}>
                    <b className={styles.label7}>Low Prices</b>
                  </div>
                  <div className={styles.featuretext}>
                    <div className={styles.label8}>
                      Our highly affordable pricing sets our business apart. Due
                      to our network of international partners, we are able to
                      supply medical products that fit your budget and that of
                      your patients or customers.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.featureSlot2}>
                <div className={styles.feature2}>
                  <div className={styles.iconBox}>
                    <img
                      className={styles.medicineSyrupIcon}
                      alt=""
                      src="../ship.svg"
                    />
                  </div>
                  <div className={styles.featureLabel}>
                    <b className={styles.label7}>Fast Delivery</b>
                  </div>
                  <div className={styles.featuretext}>
                    <div className={styles.label8}>
                      Our customers rely on us to deliver the products they need
                      for their medical clinics in a timely manner. So we ensure
                      all products are delivered on time and without hassle.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.featuresRow}>
              <div className={styles.featureSlot}>
                <div className={styles.feature}>
                  <div className={styles.iconBox}>
                    <img
                      className={styles.medicineSyrupIcon}
                      alt=""
                      src="../shieldcheck.svg"
                    />
                  </div>
                  <div className={styles.featureLabel}>
                    <b className={styles.label7}>Trusted Brands</b>
                  </div>
                  <div className={styles.featuretext}>
                    <div className={styles.label8}>
                      We only stock authentic, high-quality products, including
                      the brand names your patients know and love.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.featureSlot}>
                <div className={styles.feature}>
                  <div className={styles.iconBox}>
                    <img
                      className={styles.medicineSyrupIcon}
                      alt=""
                      src="../headset.svg"
                    />
                  </div>
                  <div className={styles.featureLabel}>
                    <b className={styles.label7}>Great Customer Service</b>
                  </div>
                  <div className={styles.featuretext}>
                    <div className={styles.label8}>
                      We know that professional and reliable service lies at the
                      heart of any successful business. Our team works hard so
                      that all our customers are satisfied with their products
                      and the service they receive.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.featureSlot}>
                <div className={styles.feature2}>
                  <div className={styles.iconBox}>
                    <img
                      className={styles.medicineSyrupIcon}
                      alt=""
                      src="../world.svg"
                    />
                  </div>
                  <div className={styles.featureLabel}>
                    <b className={styles.label7}>Global Reach</b>
                  </div>
                  <div className={styles.featuretext}>
                    <div className={styles.label8}>
                      We source our products from suppliers across the globe.
                      With such a wide network, we are able to ship to any
                      country the customer may be located in.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={styles.contactSection}
          data-scroll-to="contactSectionContainer"
        >
          <div className={styles.contactContent} id="contactSectionContainer">
            <ContactForm></ContactForm>
          </div>
          <div className={styles.contactDetailsSignIn}>
            <div className={styles.contactDetails}>
              <div>
                <img
                  className={styles.cdihealthlogoIcon}
                  alt=""
                  src="../group.svg"
                />
              </div>
              <div className={styles.title1}>
                <div className={styles.display5}>Need Anything?</div>
              </div>
              <div className={styles.visibleDetails}>
                SIMPLY EXPRESS LIMITED
                <div className={styles.details}>
                  <div className={styles.label19}>Company Number:</div>
                  <b className={styles.label7}> 14494997</b>
                </div>
                <div className={styles.details}>
                  <div className={styles.label19}>Company Address:</div>
                  <b className={styles.label7}>
                    {" "}
                    5 Edmund Road, Bristol,United Kingdom, Postcode: BS14 OBG
                  </b>
                </div>
                <div className={styles.details}>
                  <div className={styles.label19}>Call us:</div>
                  <b className={styles.label7}>
                    {" "}
                    <a href="tel:+44 117 369 0554">+44 117 369 0554</a>
                  </b>
                </div>
                <div className={styles.details}>
                  <div className={styles.label19}>Email:</div>
                  <b className={styles.label7}>
                    {" "}
                    <a href="mailto:office@simplyexpressltd.net">
                      office@simplyexpressltd.net
                    </a>
                  </b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;

export const Head = () => (
  <SEO title="Simply Express" description="Page description" />
);
